.root {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 15px;
  right: 25px;
  z-index: 9999;
  pointer-events: none;
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .root {
    left: 50%;
    right: 0;
    bottom: 50px;
    transform: translateX(-50%);
    width: 90%;
  }
}
