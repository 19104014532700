@import '../node_modules/remixicon/fonts/remixicon.css';
/* Globals and overwrites here */

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

:root{
  --primary: #9EE870;
  --secondary: #1E1E1E;
}

body,
html,
#root,
.makeStyles-root-1,
main {
  max-height: 100%;
}

a {
  cursor: pointer;
}

input {
  -webkit-appearance: none;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #cb7c7a !important;
  display: inherit;
}


@media screen and (max-width: 600px) {
  .MuiToolbar-regular {
    min-height: 48px !important;
  }

  .intercom-lightweight-app {
    display: none;
  }
}



.MuiSwitch-thumb {
  height: 15px !important;
  width: 15px !important;
}

.MuiSwitch-track {
  height: 17px !important;
  position: relative;
  top: -4px;
  border-radius: 10px !important;
}

.MuiSwitch-switchBase {
  transform: translateX(4px);
}

.MuiSwitch-switchBase.Mui-checked {
  transform: translateX(21px) !important;
}

.quill {
  margin: 8px 0;
}

.ql-toolbar {
  box-shadow: 0px 0px 2px rgba(52, 58, 67, 0.10), 0px 1px 2px rgba(52, 58, 67, 0.08), 0px 1px 4px rgba(52, 58, 67, 0.08);
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 1, 1);
  border-radius: 8px 8px 0 0;
  border: none !important;
}

.ql-container {
  border: none !important;
}

.legalq .ql-editor {
  min-height: 18em;
}

.ql-editor {
  box-shadow: 0px 0px 2px rgba(52, 58, 67, 0.10), 0px 1px 2px rgba(52, 58, 67, 0.08), 0px 1px 4px rgba(52, 58, 67, 0.08);
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 1, 1);
  border-radius: 0 0 8px 8px;
  border: none;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(85deg, var(--primary) 0%, var(--primary) 100%);
}

*::-webkit-scrollbar-thumb:hover {
  /* background: rgba(52, 8, 100, .7); */
  background: var(--primary);
}